import React from 'react';
// Framer
import { motion } from 'framer-motion';
import { leftAnimate, rightAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';

const Technology: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <motion.section
      className={classes.page} data-container="technology"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className="container">
        <motion.h2 variants={leftAnimate} custom={1} className={classes.title}>
          <Trans
            t={t} i18nKey="homePage.technology.title"
            components={{ span: <span /> }}
          />
        </motion.h2>
        <motion.p variants={leftAnimate} custom={2} className={classes.subtitle}>
          {t('homePage.technology.subtitle')}
        </motion.p>
        <div className={classes.items}>
          <motion.div variants={leftAnimate} custom={3} className={classes.item}>
            <img src="/images/icons/our-technology/1.svg" alt={t('homePage.technology.dynamicFunctionality')} className={classes.icon} />
            <img src="/images/icons/our-technology/1_desktop.svg" alt={t('homePage.technology.dynamicFunctionality')} className={[classes.icon, [classes.icon_desktop]].join(' ')} />
            <div>
              <h3 className={classes.item_title}>{t('homePage.technology.dynamicFunctionality')}</h3>
              <p className={classes.item_text}>{t('homePage.technology.itemText1')}</p>
            </div>
          </motion.div>
          <motion.div variants={rightAnimate} custom={3} className={classes.item}>
            <img src="/images/icons/our-technology/2.svg" alt={t('homePage.technology.alwaysReady')} className={classes.icon} />
            <img src="/images/icons/our-technology/2_desktop.svg" alt={t('homePage.technology.alwaysReady')} className={[classes.icon, [classes.icon_desktop]].join(' ')} />
            <div>
              <h3 className={classes.item_title}>{t('homePage.technology.alwaysReady')}</h3>
              <p className={classes.item_text}>{t('homePage.technology.itemText2')}</p>
            </div>
          </motion.div>
          <motion.div variants={leftAnimate} custom={4} className={classes.item}>
            <img src="/images/icons/our-technology/3.svg" alt={t('homePage.technology.singlePlace')} className={classes.icon} />
            <img src="/images/icons/our-technology/3_desktop.svg" alt={t('homePage.technology.singlePlace')} className={[classes.icon, [classes.icon_desktop]].join(' ')} />
            <div>
              <h3 className={classes.item_title}>{t('homePage.technology.singlePlace')}</h3>
              <p className={classes.item_text}>{t('homePage.technology.itemText3')}</p>
            </div>
          </motion.div>
          <motion.div variants={rightAnimate} custom={4} className={classes.item}>
            <img src="/images/icons/our-technology/4.svg" alt={t('homePage.technology.scalability')} className={classes.icon} />
            <img src="/images/icons/our-technology/4_desktop.svg" alt={t('homePage.technology.scalability')} className={[classes.icon, [classes.icon_desktop]].join(' ')} />
            <div>
              <h3 className={classes.item_title}>{t('homePage.technology.scalability')}</h3>
              <p className={classes.item_text}>{t('homePage.technology.itemText4')}</p>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
}

export default Technology;
