import React from 'react';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate, rotateAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';

const Security:React.FC = () => {
  const { t } = useTranslation('common');
  return (
    <motion.section
      className={classes.page} data-container="security"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <div className="container">
        <motion.h2 variants={bottomAnimate} custom={1} className={classes.title}>
          <Trans
            t={t} i18nKey="homePage.security.title"
            components={{ span: <span /> }}
          />
        </motion.h2>
        <motion.p variants={bottomAnimate} custom={2} className={classes.subtitle}>{t('homePage.security.subtitle')}</motion.p>
        <motion.div
          className={classes.items}
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.5 }}
        >
          <div className={classes.item}>
            <motion.img variants={rotateAnimate} custom={3} src="/images/icons/security/1.svg" alt="" className={classes.icon} />
            <p className={classes.item_text}>{t('homePage.security.itemText1')}</p>
          </div>
          <div className={classes.item}>
            <motion.img variants={rotateAnimate} custom={3} src="/images/icons/security/2.svg" alt="" className={classes.icon} />
            <p className={classes.item_text}>{t('homePage.security.itemText2')}</p>
          </div>
          <div className={classes.item}>
            <motion.img variants={rotateAnimate} custom={3} src="/images/icons/security/3.svg" alt="" className={classes.icon} />
            <p className={classes.item_text}>{t('homePage.security.itemText3')}</p>
          </div>
          <div className={classes.item}>
            <motion.img variants={rotateAnimate} custom={3} src="/images/icons/security/4.svg" alt="" className={classes.icon} />
            <p className={classes.item_text}>{t('homePage.security.itemText4')}</p>
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
}

export default Security;
