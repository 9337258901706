import React, { useState } from 'react';
import classNames from 'classnames';
// MUI
import { Tab, Tabs } from '@mui/material';
// Framer
import { motion } from 'framer-motion';
import { leftAnimate, opacityAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';

const Users:React.FC = () => {
  const { t } = useTranslation('common');

  const [active, setActive] = useState(1);
  const handleTab = (id: number) => setActive(id);

  const tabs = [
    { id: 1, title: t('homePage.users.claimsAdjusters'), textKey: "homePage.users.claimsAdjustersText" },
    { id: 2, title: t('homePage.users.attorneys'), textKey: "homePage.users.attorneysText" },
    { id: 3, title: t('homePage.users.consultants'), textKey: "homePage.users.consultantsText" },
    { id: 4, title: t('homePage.users.expertWitnesses'), textKey: "homePage.users.expertWitnessesText" },
    { id: 5, title: t('homePage.users.cSuiteExecutives'), textKey: "homePage.users.cSuiteExecutivesText" },
  ];

  return (
    <motion.section
      className={classes.page} data-container="users"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <motion.img variants={opacityAnimate} custom={2} src="/images/users_img.jpg" alt="" className={classes.img} />
      <div className="container">
        <div style={{ maxWidth: '688px' }}>
          <motion.h2 variants={leftAnimate} custom={1} className={classes.title}>
            <Trans
              t={t} i18nKey="homePage.users.title"
              components={{ span: <span />, br: <br /> }}
            />
          </motion.h2>
          <motion.div variants={leftAnimate} custom={2}>
            <Tabs
              value={active}
              onChange={(_, value:number) => handleTab(value)}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              className={classes.tabs}
            >
              {tabs.map(tab => (
                <Tab key={tab.id} label={tab.title} value={tab.id} className={classNames(classes.tab, {
                  [classes['tab--active']]: active === tab.id
                })} />
              ))}
            </Tabs>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.2 }}
          >
            <motion.div variants={opacityAnimate} custom={3}>
              <p className={classes.text}>
                <Trans
                  t={t} i18nKey={tabs[active - 1].textKey}
                  components={{ br: <br /> }}
                />
              </p>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
}

export default Users;
