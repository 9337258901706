import React from 'react';
// Framer
import { motion } from 'framer-motion';
import { leftAnimate, opacityAnimate } from 'utilities/Framer';
// Styles
import classes from './styles.module.scss';
// i18next
import { Trans, useTranslation } from 'react-i18next';

const Training:React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <motion.section
      className={classes.page}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.1 }}
    >
      <motion.img variants={opacityAnimate} custom={1} src="/images/training_img.jpg" alt="" className={classes.img} />
      <div className="container" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
        <motion.div
          className={classes.content}
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.5 }}
        >
          <motion.h2 variants={leftAnimate} custom={1} className={classes.title}>{t('homePage.training.title')}</motion.h2>
          <motion.p variants={leftAnimate} custom={2} className={classes.subtitle}>
            <Trans
              t={t} i18nKey="homePage.training.subtitle"
              components={{ br: <br /> }}
            />
          </motion.p>
        </motion.div>
      </div>
    </motion.section>
  );
}

export default Training;
