import IRelease from "models/Release";

export const releasesContent:IRelease[] = [
  {
    title: "Case Chronology v12.4.0",
    date: "June 24, 2024",
    notes: [
      "Case creation from case portal update.",
      "Introduced AI Chat Enable/Disable capabilities with proper documents auto-index/re-index support",
      "Improved AI Chat timeouts handling",
    ],
    bugFixes: [
      "Removed AI Chat limitation with max 2500 pages indexed per case.",
    ]
  },{
    title: "Case Chronology v12.3.0",
    date: "May 31, 2024",
    notes: [
      "AI Chat Refine feature.",
      "Support of AI Chat conversation for specific pages only (based on workspace filters and search result).",
    ],
    bugFixes: [
      "Differentiate billing invoices numbering per region.",
    ]
  },{
    title: "Case Chronology v12.2.0",
    date: "May 14, 2024",
    notes: [
      "Support of new custom document format (manually created on Case Portal).",
      "Company account workflow settings support - case transition to status Open options.",
      "Bulk actions for case pages search result.",
      "Limit platform users contact data exposed"
    ],
    bugFixes: [
      "Documents upload validation and error handing enhanced",
      "Fixed background update on push notifications issues"
    ]
  },{
    title: "Case Chronology v12.1.0",
    date: "April 18, 2024",
    notes: [
      "Deprecate old reports and report section and enabled new reports and templates for all the company accounts.",
      "Original source references support for AI Chat.",
      "Report watermark feature support added",
      "Grant permission for all the user roles to export and download visible pages.",
      "OCR service upgrade to newer version." 
    ]
  },{
    title: "Case Chronology v12.0.0",
    date: "April 1, 2024",
    notes: [
      "DMS module launched with manageable company account collections.",
      "Support of document collections of different file types and OCR/non-OCR.",
      "Possibility to upload documents from Case Portal.",
      "Added possibility to export individual page or staple of pages to PDF.",
      "Possibility to upload directories.",
      "Added episode RTF editor full screen mode"
    ],
    bugFixes: [
      "Reports generation performance optimization",
      "Documents upload progress indicator fix"
    ],
  },{
    title: "Case Chronology v11.6.0",
    date: "February 27, 2024",
    notes: [
      "Platform user type support for Case Chronology expert teams.",
      "Presentation mode converted into trial mode.",
      "New report manageable page header and footer",
      "Added conflict check with warning on case notes and labels update",
      "Added episode RTF editor full screen mode"
    ],
    bugFixes: [
      "Stapling and parent episode issue fixed"
    ],
  },
  {
    title: "Case Chronology v11.5.0",
    date: "February 9, 2024",
    notes: [
      "AI Chat top relevant pages config added, UX updates.",
      "Updated episode CPT data copy&paste function to inlcude CPT modifiers",
      "Template editor copy&paste enhancements."
    ],
    bugFixes: [
      "Improved data runtime backgorund updates.",
      "Stapple button double click prevention."
    ],
  },
  {
    title: "Case Chronology v11.4.0",
    date: "January 29, 2024",
    notes: [
      "New AI Chat beta version release.",
      "Added font related settings for template and default report template settings.",
      "Added filters for case duplicate function."
    ],
    bugFixes: [
      "Improved runtime background updte for page episodes.",
      "Stapled pages UX improvements  for page modal view."
    ],
  }
]